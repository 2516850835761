<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
  getSmallestThumbnailUrl,
} from '@shopware-pwa/helpers-next';
import type { Category } from '@shopware-pwa/types';
const { navigationElements } = useNavigation();
const currentMenuPosition = ref<string | null>(null);
const currentChildMenuPosition = ref<string | null>(null);
const activeNavElem = ref<Category | null>(null);

const menuHtmlElement = ref(null);
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

defineProps<{ isMenuActive: boolean }>();

onClickOutside(menuHtmlElement, () => {
  currentMenuPosition.value = null;
  currentChildMenuPosition.value = null;
});

const route = useRoute();

watch(
  () => route.fullPath,
  () => true,
);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <nav
    class="relative hidden items-center space-x-6 lg:flex lg:pr-8 lg:text-[1.15rem]"
    aria-label="Top navigation"
    role="menu"
  >
    <!--  
       ref="menuHtmlElement" was removed because of nuxt/vue bug
       https://github.com/nuxt/nuxt/issues/13309

    -->
    <div
      v-for="navigationElement in navigationElements"
      :key="navigationElement.id"
      class="navigation-elem relative"
      :class="
        route.path.toLowerCase().indexOf(
          getTranslatedProperty(navigationElement, 'name')
            .toLowerCase()
            .replace(/\u00e4/g, 'ae')
            .replace(/\u00d6/g, 'oe')
            .replace(/\u00dc/g, 'ue'),
        ) > 0
          ? 'active-nav-elem'
          : ''
      "
      @click="currentMenuPosition = navigationElement.id"
    >
      <!-- Show link or Button if child items -->
      <span
        v-if="navigationElement?.children?.length > 0"
        role="menuitem"
        class="lh-0 text-dark !hover:text-grey mt-2 inline-block cursor-pointer p-2 font-medium uppercase"
        @click="
          $emit('openMenu');
          $emit('setActiveNav', navigationElement);
          $emit('setActiveChildNav');
        "
        >{{ getTranslatedProperty(navigationElement, 'name') }}</span
      >
      <NuxtLink
        v-else
        role="menuitem"
        :target="
          navigationElement.externalLink || navigationElement.linkNewTab
            ? '_blank'
            : ''
        "
        :to="formatLink(getCategoryRoute(navigationElement))"
        class="lh-0 text-dark !hover:text-grey mt-2 inline-block p-2 font-medium uppercase"
        @click="$emit('closeMenu')"
        >{{ getTranslatedProperty(navigationElement, 'name') }}
      </NuxtLink>
      <!--
            Flyout menu, show/hide based on flyout menu state.

            Entering: "transition ease-out duration-200"
              From: "opacity-0 translate-y-1"
              To: "opacity-100 translate-y-0"
            Leaving: "transition ease-in duration-150"
              From: "opacity-100 translate-y-0"
              To: "opacity-0 translate-y-1"
          -->
    </div>
  </nav>
</template>
<style lang="scss">
.navigation-elem {
  &.active-nav-elem {
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      background: var(--color-dark);
      left: 0;
      bottom: -1em;
    }
  }
}
</style>
